<template>
  <div class="line-notify-callback">
    <div class="line-callback-background__icon">
      <img src="@/assets/images/icon.svg" alt="background">
    </div>
    <div class="card line-callback-card text-center">
      <div class="card-body px-4 py-5 p-sm-5">
        <div class="line-callback-title mb-3">{{ title }}</div>
        <p v-for="(info, index) in infos" :key="'line-callback-' + index">
          {{ info }}
        </p>
        <button
          type="button"
          class="btn btn-primary rounded-pill mt-3"
          @click="closeWindow"
          >{{ $t('__closeWindow') }}</button
        >
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang/lang.js'
import { line } from '@/http/api/line.js'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'LineNotifyCallBack',
  data () {
    return {
      title: null,
      infos: null
    }
  },
  computed: {
    ...mapState(['token'])
  },
  methods: {
    ...mapMutations(['Loaded', 'Loading', 'AlertsInfo', 'Alerted', 'getToken']),
    closeWindow () {
      window.close()
    },
    callBack () {
      const vm = this
      vm.Loading()
      line.lineIssue({
        code: vm.$route.query.code,
        callbackUri: vm.$route.query.path
      }, vm.token).then((res) => {
        vm.Loaded()
        const feaback = res.data
        if (res.status <= 201) {
          if (feaback.status === 'success') {
            vm.title = i18n.t('__LINENotifyAuthorizeSuccess')
            vm.infos = [i18n.t('__LINENotifyAuthorizeSuccessInfo')]
          } else {
            vm.title = i18n.t('__LINENotifyAuthorizeFailure')
            vm.infos = [feaback.errMsg, i18n.t('__LINENotifyAuthorizeFailureInfo')]
          }
        } else {
          vm.title = i18n.t('__LINENotifyAuthorizeFailure')
          vm.infos = [feaback.errMsg, i18n.t('__LINENotifyAuthorizeFailureInfo')]
        }
      }).catch((err) => {
        vm.Loaded()
        vm.title = i18n.t('__LINENotifyAuthorizeFailure')
        vm.infos = [err, i18n.t('__LINENotifyAuthorizeFailureInfo')]
      })
    }
  },
  watch: {
    token (data) {
      if (data.headers.Authorization) {
        this.callBack()
      }
    }
  },
  created () {
    this.getToken()
  },
  mounted () {
    if (this.token.headers.Authorization) {
      this.callBack()
    }
  }
}
</script>

<style lang="scss">
.line-callback{
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #EEF0F8;
  position: relative;
  // color: $primary;
  &-logo {
    max-width: 250px;
    width: 70%;
    margin: 20px auto;
    img {
      width: 100%;
    }
  }
  &-background__icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: #e3fdfb;
    img{
      width: 1000px;
      position: absolute;
      top: 30%;
      left: 27%;
      transform: translate(-50%, -50%);
      filter: blur(40px);
      opacity: 0.6;
    }
  }
  &-title{
    font-size: 30px;
    font-weight: 500;
  }
  &-card{
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: rgba(#fff, 0.6);
    border: none;
  }
}
</style>
